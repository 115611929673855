import { createAsyncThunk } from "@reduxjs/toolkit";
import { playersService } from "../../services";
// import {
//   fakeM3u8AndMp4,
//   fakeProdmp4,
//   fakeProdm3u8,
//   videos,
//   videosWithMessage,
//   ski,
//   onlyMessages,
// } from "../../mockdata/mockdata";

export const fetchContent = createAsyncThunk(
  "players/fetchContent",
  async (fetchInfos, { rejectWithValue }) => {
    // return fakeM3u8AndMp4;
    const response = await playersService.fetchContent(
      fetchInfos.iv,
      fetchInfos.content
    );
    if (!response) return rejectWithValue("offline");
    return response;
  }
);

export const likeContent = createAsyncThunk(
  "players/likeContent",
  async (likeInfos, { rejectWithValue }) => {
    try {
      const response = await playersService.like(
        likeInfos.iv,
        likeInfos.content,
        likeInfos.id
      );
      if (!response) return rejectWithValue("offline");
      return likeInfos.storyIndex;
    } catch {
      return rejectWithValue("failed to like");
    }
  }
);

export const unlikeContent = createAsyncThunk(
  "players/unlikeContent",
  async (unlikeInfos, { rejectWithValue }) => {
    try {
      const response = await playersService.unlike(
        unlikeInfos.iv,
        unlikeInfos.content,
        unlikeInfos.id
      );
      if (!response) return rejectWithValue("offline");
      return unlikeInfos.storyIndex;
    } catch {
      return rejectWithValue("failed to unlike");
    }
  }
);
