import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Footer from "./Footer";
import { playersService } from "../../services";
import SideBar from "./SideBar";
import { useDispatch, useSelector } from "react-redux";
import { Button, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { openModal, playingStatus } from "../../reducers/players/playersSlice";

const LINE_CLAMP = 15;
const CARACTERS_PER_LINE = 30;

const PREFIX = "MessageElement";

const classes = {
  messageContainer: `${PREFIX}-messageContainer`,
  messageContent: `${PREFIX}-messageContent`,
  paperContainer: `${PREFIX}-paperContainer`,
  showMoreButton: `${PREFIX}-showMoreButton`,
  title: `${PREFIX}-title`,
  messageComponentLogo: `${PREFIX}-messageComponentLogo`,
  readMoreButton: `${PREFIX}-readMoreButton`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.messageContainer}`]: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    backgroundColor: "#121212",
    width: "100%",
    height: "100%",
    scrollSnapAlign: "start",
    "& > div": {
      border: "none",
    },
    zIndex: "90",
  },

  [`& .${classes.messageContent}`]: {
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    height: "100%",
    width: "85%",
    top: "20%",
    maxHeight: "300px",
    textAlign: "center",
    position: "absolute",
    padding: "10px 39px",

    [theme.breakpoints.between("xs", "sm")]: {
      padding: "10px 25px",
    },
  },

  [`& .${classes.paperContainer}`]: {
    position: "absolute",
    top: "20%",
    height: "55%",
    padding: "1rem 2.7rem 1rem 1.7rem",
  },

  [`& .${classes.showMoreButton}`]: {
    position: "absolute",
    bottom: "3rem",
    left: "calc(50% - 1.5rem)",
    transform: "translateX(-50%)",
  },

  [`& .${classes.title}`]: {
    height: "30px",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  },

  [`& .${classes.messageComponentLogo}`]: {
    width: "90%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    objectFit: "contain",
  },
  [`& .${classes.readMoreButton}`]: {
    position: "absolute",
    top: "55%",
  },
}));

const MessageElement = ({
  token,
  verticalPlayer,
  isActive,
  message,
  myVideoTag,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [once, setOnce] = useState(false);

  const currentElement = useSelector(
    (state) => state.players.playlist[state.players.currentPlayingIndex]
  );
  const firstTime = useSelector((state) => state.players.firstTime);

  useEffect(() => {
    if (isActive && !firstTime) dispatch(playingStatus(true));
    if (isActive && myVideoTag) myVideoTag.pause();
  }, [isActive, firstTime, dispatch, myVideoTag]);

  useEffect(() => {
    if (isActive) {
      if (!once) {
        playersService.playerContentSeen(token, currentElement.id);
      }
      setOnce(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  return (
    <Root className={classes.messageContainer}>
      <Paper
        elevation={8}
        className={classes.paperContainer}
        sx={{
          margin: verticalPlayer ? "0 0 0 1.5rem" : "0 1.5rem",
          width: verticalPlayer ? "70%" : "75%",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: LINE_CLAMP,
          }}
        >
          {message}
        </Typography>
        {message?.length > LINE_CLAMP * CARACTERS_PER_LINE && (
          <Button
            onClick={(e) => dispatch(openModal("comment"))}
            className={classes.showMoreButton}
          >
            {t("messageDialog.discoverMessage")}
          </Button>
        )}
      </Paper>
      {/* <Typography
        variant="h6"
        align="center"
        className={classes.title}
      >
        {t('messageDialog.someoneLetMessage')}
      </Typography>
      <Button
        className={classes.readMoreButton}
        variant="contained"
        color="primary"
        disableRipple
        onClick={(e) => dispatch(openModal('comment'))}
      >
        {t('messageDialog.discoverMessage')}
      </Button> */}
      <Footer verticalPlayer={verticalPlayer} />
      <SideBar token={token} />
    </Root>
  );
};
export default MessageElement;
