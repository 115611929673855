import React, { useCallback, useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { Typography, LinearProgress, CircularProgress } from "@mui/material";
import Footer from "./Footer";
import SideBar from "./SideBar";
import Logo from "../../assets/logo_ads.png";
import PlayLogo from "../../assets/play.png";
import { playersService } from "../../services";
import ErrorIcon from "@mui/icons-material/Error";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  playingStatus,
  updateFirstVideoInteraction,
} from "../../reducers/players/playersSlice";
import Hls from "hls.js";

const PREFIX = "VideoElement";

const classes = {
  video: `${PREFIX}-video`,
  videoPlayer: `${PREFIX}-videoPlayer`,
  videoPlayButton: `${PREFIX}-videoPlayButton`,
  playButtonImage: `${PREFIX}-playButtonImage`,
  logoAds: `${PREFIX}-logoAds`,
  title: `${PREFIX}-title`,
  progressBar: `${PREFIX}-progressBar`,
  errorContainer: `${PREFIX}-errorContainer`,
  errorContent: `${PREFIX}-errorContent`,
  errorIcon: `${PREFIX}-errorIcon`,
  errorText: `${PREFIX}-errorText`,
  elementDescriptiontt: `${PREFIX}-elementDescriptiontt`,
  elementDescriptionin: `${PREFIX}-elementDescriptionin`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.video}`]: {
    position: "relative",
    backgroundColor: "black",
    width: "100%",
    height: "100%",
    scrollSnapAlign: "start",
    zIndex: "90",
  },

  [`& .${classes.videoPlayer}`]: {
    backgroundColor: "black",
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },

  [`& .${classes.videoPlayButton}`]: {
    position: "absolute",
    zIndex: 9999,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    "& > .MuiSvgIcon-root": {
      fontSize: "4rem !important",
      cursor: "pointer",
    },
  },

  [`& .${classes.playButtonImage}`]: {
    maxWidth: "300px",
    width: "100%",
  },

  [`& .${classes.logoAds}`]: {
    width: "150px",
    position: "absolute",
    left: "50%",
    top: "2.5rem",
    transform: "translateX(-50%)",
  },

  [`& .${classes.title}`]: {
    textAlign: "center",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "90%",
    position: "absolute",
    top: "0",
    left: "50%",
    transform: "translateX(-50%)",
  },

  [`& .${classes.progressBar}`]: {
    position: "absolute",
    bottom: "0",
    width: "100%",
  },

  [`& .${classes.errorContainer}`]: {
    position: "relative",
    backgroundColor: "black",
    width: "100%",
    height: "100%",
  },

  [`& .${classes.errorContent}`]: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "80%",
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translateX(-50%)",
  },

  [`& .${classes.errorIcon}`]: {
    color: "rgba(255, 255, 255, 0.7)",
    width: "130px",
    height: "130px",
  },

  [`& .${classes.errorText}`]: {
    marginTop: "1rem",
    textAlign: "center",
  },
  [`& .${classes.elementDescriptiontt}`]: {
    width: "95%",
    color: "white",
    position: "absolute",
    bottom: "10px",
    left: "50%",
    transform: "translateX(-50%)",
    textAlign: "center",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxHeight: "32px",
    zIndex: "15",
  },
  [`& .${classes.elementDescriptionin}`]: {
    width: "73%",
    color: "white",
    position: "absolute",
    bottom: "2rem",
    left: "50%",
    transform: "translateX(-50%)",
    textAlign: "center",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxHeight: "32px",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
}));

const VideoElement = ({
  url,
  token,
  currentIndex,
  disableProgress,
  verticalPlayer,
  isActive,
  myVideoTag,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [playing, setPlaying] = useState(false);
  const [played, setPlayed] = useState(false);
  const [videoCurrentProgress, setVideoCurrentProgress] = useState(0);
  const [error, setError] = useState(null);

  const loaderRef = useRef(false);

  const currentElement = useSelector(
    (state) => state.players.playlist[state.players.currentPlayingIndex]
  );
  const isFirstTime = useSelector((state) => state.players.firstTime);
  const isPlaying = useSelector((state) => state.players.playing);
  const muted = useSelector((state) => state.players.muted);
  const firstVideoInteraction = useSelector(
    (state) => state.players.firstInteractionWithVideo
  );

  useEffect(() => {
    const videoTag = document.querySelector("video");
    if (videoTag) myVideoTag.muted = muted;
  }, [muted, myVideoTag]);

  const handleVideoError = (err) => {
    console.log("error on video =>", err);
    setError(true);
  };

  const handleProgressError = useCallback(() => {
    setVideoCurrentProgress(
      (myVideoTag.currentTime / myVideoTag.duration) * 100
    );
  }, [myVideoTag]);

  useEffect(() => {
    if (isActive) {
      const videoElement = document.getElementById(
        `videoPlayer-${currentIndex}`
      );
      if (Hls.isSupported() && url.endsWith(".m3u8")) {
        const hls = new Hls();
        try {
          hls.loadSource(url);
        } catch (e) {
          console.log("error hls on m3u8 =>", e);
        }
        hls.attachMedia(myVideoTag);
      } else myVideoTag.src = url;
      dispatch(playingStatus(true));
      myVideoTag.addEventListener("error", (err) => handleVideoError(err));
      myVideoTag.addEventListener("timeupdate", handleProgressError);
      if (!played) {
        playersService.playerContentSeen(token, currentElement.id);
        setPlayed(true);
      }
      setPlaying(true);
      videoElement.append(myVideoTag);
    }
    return () => {
      myVideoTag.removeEventListener("error", handleVideoError);
      myVideoTag.addEventListener("timeupdate", handleProgressError);
    };
  }, [
    isActive,
    currentIndex,
    myVideoTag,
    url,
    dispatch,
    currentElement.id,
    played,
    token,
    handleProgressError,
  ]);

  return (
    <Root className={classes.video}>
      {error && (
        <div className={classes.errorContainer}>
          <div className={classes.errorContent}>
            <ErrorIcon className={classes.errorIcon} />
            <Typography variant="h5" className={classes.errorText}>
              {t("player.videoUnavailableTitle")}
            </Typography>
            <Typography variant="body1" className={classes.errorText}>
              {t("player.videoUnavailable")}
            </Typography>
          </div>
        </div>
      )}
      {/* {currentElement.description && !isFirstTime && !error ?
        <Typography className={verticalPlayer ? classes.elementDescriptiontt : classes.elementDescriptionin}>
          {currentElement.description}
        </Typography>
        :
        null
      } */}
      {loaderRef.current && isPlaying && (
        <CircularProgress
          size={80}
          sx={{
            position: "absolute",
            top: "46%",
            left: "40%",
            transform: "translate(-46%, -40%)",
          }}
        />
      )}
      <div
        className={classes.videoPlayer}
        id={`videoPlayer-${currentIndex}`}
        style={{
          display: error ? "none" : "visible",
        }}
      />
      {playing && isActive && !error && !disableProgress && (
        <LinearProgress
          variant="determinate"
          value={videoCurrentProgress}
          className={classes.progressBar}
        />
      )}
      {!error && <Footer mutable verticalPlayer={verticalPlayer} />}
      <SideBar token={token} />
      {(!firstVideoInteraction || (!isPlaying && !disableProgress)) && (
        <div className={classes.videoPlayButton}>
          <img
            src={PlayLogo}
            alt="Play Icon"
            className={classes.playButtonImage}
            onClick={() => {
              if (!firstVideoInteraction)
                dispatch(updateFirstVideoInteraction());
              dispatch(playingStatus(true));
              myVideoTag.play();
            }}
          />
        </div>
      )}
      {isFirstTime && (
        <img src={Logo} alt="logo ArtDesignStory" className={classes.logoAds} />
      )}
    </Root>
  );
};
export default VideoElement;
